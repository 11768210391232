<template>
	<div class='a-card' :class='{"onlyRead": onlyRead}'>
		<div class='h-card-wrap'>
			<div class="h-card-chip-container">
				<div class='h-card-chip red white--text' v-if="item.ranking < 10">
					<div class='h-card-chip-content'>
						<v-icon icon='crown' size='13' />
						<p class='h-card-chip-text'>{{$t('account.search.hotels.best')}}</p>
					</div>
				</div>
				<div class='h-card-chip orange white--text' v-if="offer.discount">
					<div class='h-card-chip-content'>
						<v-icon icon='tag' size='13' />
						<p class='h-card-chip-text'>{{$t('account.search.hotels.sale')}}</p>
					</div>
					<div class='h-card-chip-action'>
						<p class='h-card-chip-text orange--text'>{{discount(offer)}}</p>
					</div>
				</div>
			</div>
			<div class='h-card-image link' @click='pushHotel(item.code)' :style='onlyRead ? "pointer-events: none;" : ""'>
				<img :src="`${item.image.indexOf('http') ? `https://tcc.ua${item.image}` : item.image}`">
			</div>
			<div class='h-card-container'>
				<div class='h-card-content'>
					<ul class='h-card-stars-list'>
						<li class='h-card-stars-item' v-for='(item, i) in item.categorySimpleCode' :key='i'>
							<v-icon icon='star' size='10' />
						</li>
					</ul>
					<p class='h-card-title link' @click='pushHotel(item.code)' :style='onlyRead ? "pointer-events: none;" : ""'>{{item.name}}</p>
					<div class="h-card-info">
						<a class='a-text-with-icon app-link' style='font-weight: 400 !important' :href='item.place' target="_blank">
							<v-icon icon='pin' size='13' />
							<span>{{item.address}}, {{item.postalCode}} {{item.countryName}}</span>
						</a>
					</div>

					<div class="h-card-content-info desktop-show">
						<p class='a-text-with-icon green--text'>
							<v-icon icon='bed' size='13' />
							<span>{{offer.roomName}}</span>
						</p>
						<p class='a-text-with-icon green--text'>
							<v-icon icon='tea' size='13' />
							<span>{{offer.boardName}}</span>
						</p>
					</div>

					<TripAdvisor
						:rating='item.rating'
						:countRewards='item.countRewards'
					/>
				</div>
				<div class="h-card-group-buttons">
					<div class="col">
						<v-button xxsmall outline color='green' class='h-card-group-button favourite' :class='{"app-btn-active favourite-active": favourite}'
							@click.stop='favourite ? hotelFavoriteUnchecked() : hotelFavoriteChecked()'
							:loading='loading_favourite'
						>
							<v-icon icon='favourite' size='14' />
							<span class='desktop-show'>{{favourite ? $t('account.buttons.remove') : $t('account.comparison.to_the_chosen_one')}}</span>
							<span class='desktop-hide'>{{favourite ? $t('account.comparison.remove_from_favorites') : $t('account.comparison.to_the_chosen_one')}}</span>
						</v-button>
					</div>
					<div class="col">
						<v-button xxsmall outline color='green' class='h-card-group-button' :class='{"app-btn-active": comparison}'
							@click.stop="comparison ? deleteComparison() : addComparison(offer.identifier)"
							:loading='loading_comparison'
						>
							<v-icon :icon='comparison ? "delete" : "graph"' size='14' class='desktop-show' />
							<span class='desktop-show'>{{comparison ? $t('account.buttons.remove') : $t('account.comparison.for_comparison')}}</span>
							<v-icon icon='delete' size='14' class='desktop-hide' v-if='comparison' />
							<span class='desktop-hide'>{{comparison ? $t('account.comparison.remove_from_comparison') : $t('account.comparison.add_to_compare')}}</span>
						</v-button>
					</div>
				</div>
			</div>
		</div>

		<div class="h-card-footer">
			<div class="h-card-footer-row">
				<div class="h-card-footer-info desktop-hide">
					<p class='a-text-with-icon green--text'>
						<v-icon icon='bed' size='13' />
						<span>{{offer.roomName}}</span>
					</p>
					<p class='a-text-with-icon green--text'>
						<v-icon icon='tea' size='13' />
						<span>{{offer.boardName}}</span>
					</p>
				</div>
				<div class="h-card-footer-action">
					<Total
						:offer="offer"
						:night="$moment(item.searchData.dateTo).diff($moment(item.searchData.dateFrom), 'days')"
					/>
					<div class="h-card-footer-action-button">
						<v-button block xxsmall :color="`${offer.isConfirmation ? 'orange' : 'green'} white--text`" class='link' @click='pushHotel(item.code)'>{{$t('account.search.hotels.view_available_numbers')}}</v-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import TripAdvisor from '@/views/account/result/hotels/tripadvisor';
	import Total from '@/views/account/result/hotels/total';
	import { hotelFavoriteChecked, hotelFavoriteUnchecked, addComparison, deleteComparison } from '@/services/request';
	import _ from 'lodash';

	export default {
		data: () => ({
			loading_favourite: false,
			loading_comparison: false
		}),
		props: ["item", "check_availability", "items_favourite", "onlyRead"],
		computed: {
			favourite() {
				return this.items_favourite ? this.items_favourite.some(el => el.code === this.item.code) : false
			},
			comparison() {
				if(!(this.check_availability && this.check_availability.items)) return;
				return Object.values(this.check_availability.items).some(el => el.code == this.item.code)
			},
			source() {
				return JSON.parse(this.$route.query.source)
			},
			offer() {
				return _.minBy(this.item.offers, 'amount')
			}
		},
		components: {
			TripAdvisor,
			Total
		},
		methods: {
			hotelFavoriteChecked() {
				this.loading_favourite = true;

				hotelFavoriteChecked(this.item.code)
					.then(() => this.$emit("update-favourite"))
					.finally(() => this.loading_favourite = false)
			},
			hotelFavoriteUnchecked() {
				this.loading_favourite = true;

				hotelFavoriteUnchecked(this.item.code)
					.then(() => this.$emit("update-favourite"))
					.finally(() => this.loading_favourite = false)
			},
			addComparison() {
				if (this.check_availability.count >= this.check_availability.maxCount) {
					this.$store.commit('notification/errorMessage', {status: 'warning', title: this.$t('account.comparison.it_is_impossible.flight')})
				} else {
					this.loading_comparison = true;

					addComparison({
						type: 'hotel',
						code: String(this.item.code),
						orderId: this.$route.params.orderId,
						storageId: this.$route.params.storageId
					}).then((res) => this.$emit("update-comparison", res.data))
						.finally(() => this.loading_comparison = false);
				}
			},
			deleteComparison() {
				this.loading_comparison = true;

				deleteComparison({
					comparisonId: this.check_availability.id,
					code: this.item.code,
				}).then((res) => this.$emit("update-comparison", res.data))
					.finally(() => this.loading_comparison = false);
			},
			pushHotel(code) {
				let route = this.$router.resolve({
					name: "hotels-result",
					params: {code: code, lang: this.$route.params.lang},
					query: {orderId: this.$route.params.orderId, storageId: this.$route.params.storageId, source: this.$route.query.source, ...(this.$route.query.tripName ? {tripName: this.$route.query.tripName} : {})}
				})

				window.open(route.href, '_blank');
			},
			discount(offer) {
				return `-${Math.ceil(100 - offer.amount * 100 / (offer.amount + offer.discount.amount))}%`
			}
		}
	}
</script>
